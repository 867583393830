const ExclamationSign = () => {
    return (
        <svg width='100' height='100' viewBox='0 0 15 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='7.5' cy='9.5' r='7' stroke='#3a4146' />
            <path
                d='M8.08375 5.77V8.63C8.08375 8.93333 8.06875 9.23167 8.03875 9.525C8.00875 9.815 7.97042 10.1233 7.92375 10.45H7.08375C7.03708 10.1233 6.99875 9.815 6.96875 9.525C6.93875 9.23167 6.92375 8.93333 6.92375 8.63V5.77H8.08375ZM6.72875 12.32C6.72875 12.2167 6.74708 12.1183 6.78375 12.025C6.82375 11.9317 6.87708 11.8517 6.94375 11.785C7.01375 11.7183 7.09542 11.665 7.18875 11.625C7.28208 11.585 7.38208 11.565 7.48875 11.565C7.59208 11.565 7.69042 11.585 7.78375 11.625C7.87708 11.665 7.95708 11.7183 8.02375 11.785C8.09375 11.8517 8.14875 11.9317 8.18875 12.025C8.22875 12.1183 8.24875 12.2167 8.24875 12.32C8.24875 12.4267 8.22875 12.5267 8.18875 12.62C8.14875 12.71 8.09375 12.7883 8.02375 12.855C7.95708 12.9217 7.87708 12.9733 7.78375 13.01C7.69042 13.05 7.59208 13.07 7.48875 13.07C7.38208 13.07 7.28208 13.05 7.18875 13.01C7.09542 12.9733 7.01375 12.9217 6.94375 12.855C6.87708 12.7883 6.82375 12.71 6.78375 12.62C6.74708 12.5267 6.72875 12.4267 6.72875 12.32Z'
                fill='#3a4146' />
        </svg>

    );
};

export default ExclamationSign;
